import React, { useState } from "react";
import { ConfigProvider } from "antd";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { PersistGate } from "redux-persist/integration/react";

import { THEME } from "./constant";
import App from "./App";
import { store, persistor } from "./redux/store";
import { useNotificationBox } from "hooks";
import { NotificationBoxContext, BreadcumbContext } from "context";

import "./base-ui/reset.css";
import "./base-ui/main.css";

const queryClient = new QueryClient({
  queries: {
    staleTime: 6000,
    cacheTime: 1000,
  },
});

const Main = () => {
  const [contextHolder, openNotification] = useNotificationBox();
  const breadcumb = useState([]);
  return (
    <>
      {contextHolder}
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <BreadcumbContext.Provider value={breadcumb}>
            <NotificationBoxContext.Provider value={openNotification}>
              <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                  <ConfigProvider theme={THEME}>
                    <App />
                  </ConfigProvider>
                </PersistGate>
              </Provider>
            </NotificationBoxContext.Provider>
          </BreadcumbContext.Provider>
        </BrowserRouter>
      </QueryClientProvider>
    </>
  );
};
const container = document.getElementById("root");
const root = createRoot(container);
root.render(React.createElement(Main));
