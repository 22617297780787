const BASE_URL = process.env.REACT_APP_URL_API;
const fetchUsageVideos = async ({ queryKey }) => {
  const lang = queryKey[1];
  const page = queryKey[2];
  if (!lang || !page) return [];
  const apiRest = await fetch(
    `${BASE_URL}/usage/video?lang=${lang}&page=${page}`
  );
  return apiRest.json();
};
const fetchOneUsageVideo = async ({ queryKey }) => {
  const { id, lang } = queryKey[1];
  if (!lang || !id) return [];
  const apiRest = await fetch(
    `${BASE_URL}/usage/get-one-video-usage?lang=${lang}&id=${id}`
  );
  return apiRest.json();
};

const postUsageVideo = async (data) => {
  const apiRest = await fetch(`${BASE_URL}/usage/video`, {
    method: "POST",
    body: data,
  });
  return apiRest.json();
};

const updateUsageVideo = async ({ data, id }) => {
  console.log(data, id);
  const apiRest = await fetch(`${BASE_URL}/usage/video?id=${id}`, {
    method: "PATCH",
    body: data,
  });
  return apiRest.json();
};

const deleteUsageVideo = async (id) => {
  const apiRest = await fetch(`${BASE_URL}/usage/video?id=${id}`, {
    method: "DELETE",
    body: {},
  });

  return apiRest.json();
};

export {
  fetchUsageVideos,
  postUsageVideo,
  fetchOneUsageVideo,
  deleteUsageVideo,
  updateUsageVideo,
};
