import { createSlice } from "@reduxjs/toolkit";
import i18n from "../translations/i18n";

const initialState = {
  lang: i18n.language,
  languesList: [
    {
      label: "Français (Fr)",
      key: "fr",
      value: "fr",
    },
    {
      label: "Anglais (En)",
      key: "en",
      value: "en",
    },
    {
      label: "Kirundi (Kr)",
      key: "kr",
      value: "kr",
    },
  ],
};

const dataSlice = createSlice({
  name: "data",
  initialState,
  reducers: {
    setLanguage: (state, { payload }) => {
      state.lang = payload;
    },
  },
});

export const { setLanguage } = dataSlice.actions;
export default dataSlice.reducer;
