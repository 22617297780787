const BASE_URL = process.env.REACT_APP_URL_API;

const loginUser = async (data) => {
  const apiRest = await fetch(`${BASE_URL}/auth/login`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });

  return apiRest.json();
};

export { loginUser };
