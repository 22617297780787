import { notification } from "antd";
const TYPE = {
  success: "success",
  failed: "error",
};
const useNotificationBox = () => {
  const [api, contextBoxNotif] = notification.useNotification();
  const openNotification = (config) => {
    api[TYPE[config.status]]({
      message: "Message !",
      description: config.msg,
      duration: 4,
    });
  };
  return [contextBoxNotif, openNotification];
};
export default useNotificationBox;
