import { useQuery } from "@tanstack/react-query";
import { fetchDashboardStatisque } from "services/dashboardApi";

const useQuerieDashboardStat = (lang) => {
  const { data, isPending } = useQuery({
    queryKey: ["dashboard-static", lang],
    queryFn: fetchDashboardStatisque,
  });

  return [data?.data ?? [], isPending];
};
export { useQuerieDashboardStat };
