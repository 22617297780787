import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import sessionStorage from "redux-persist/lib/storage/session";
import { thunk } from "redux-thunk";
import logger from "redux-logger";

import dataReducer from "./DataSlice";
import userReducer from "./UserSlice";

const middlewares = [];
if (process.env.REACT_APP_NODE_ENV === "development") {
  //this for display logger in console when we are developping
  middlewares.push(logger);
}

const persistConfig = {
  key: "root-fishcoach",
  storage: sessionStorage,
  whitelist: ["user"],
};

const rootReducer = combineReducers({
  data: dataReducer,
  user: userReducer,
});
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.REACT_APP_NODE_ENV !== "production",
  middleware: [thunk],
});

export const persistor = persistStore(store);
