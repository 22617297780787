const BASE_URL = process.env.REACT_APP_URL_API;

const fetchCategoryProjectList = async ({ queryKey }) => {
  const lang = queryKey[1];
  const page = queryKey[2];
  if (!lang || !page) return [];
  const apiRest = await fetch(
    `${BASE_URL}/planification/categorie?lang=${lang}&page=${page}`
  );
  return apiRest.json();
};
const postCategoryProject = async (data) => {
  const apiRest = await fetch(`${BASE_URL}/planification/categorie`, {
    method: "POST",
    body: data,
  });
  return apiRest.json();
};
const updateCategoryProject = async ({ data, id }) => {
  const apiRest = await fetch(`${BASE_URL}/planification/categorie?id=${id}`, {
    method: "PATCH",
    body: data,
  });
  return apiRest.json();
};
const deleteCategoryProject = async (id) => {
  const apiRest = await fetch(`${BASE_URL}/planification/categorie?id=${id}`, {
    method: "DELETE",
    body: {},
  });
  return apiRest.json();
};
const fetchCategoryProjectListNoPage = async ({ queryKey }) => {
  const lang = queryKey[1];
  if (!lang) return [];
  const apiRest = await fetch(
    `${BASE_URL}/planification/categorie-all?lang=${lang}`
  );
  return apiRest.json();
};

const fetchProjetsList = async ({ queryKey }) => {
  const lang = queryKey[1];
  const page = queryKey[2];
  if (!lang || !page) return [];
  const apiRest = await fetch(
    `${BASE_URL}/planification/projets?lang=${lang}&page=${page}`
  );
  return apiRest.json();
};

const fetchChampsFixeList = async ({ queryKey }) => {
  const [_, lang, page] = queryKey;
  if (!lang || !page) return [];
  const apiRest = await fetch(
    `${BASE_URL}/planification/field-fixe?lang=${lang}&page=${page}`
  );
  return apiRest.json();
};

const fetchProjetDetail = async ({ queryKey }) => {
  const { lang, id } = queryKey[1];
  if (!id) return [];
  const apiRest = await fetch(
    `${BASE_URL}/planification/projet-detail?projetId=${id}&lang=${lang}`
  );
  return apiRest.json();
};

const fetchCultureList = async ({ queryKey }) => {
  const lang = queryKey[1];
  const page = queryKey[2];
  if (!lang || !page) return [];
  const apiRest = await fetch(
    `${BASE_URL}/planification/culture?lang=${lang}&page=${page}`
  );
  return apiRest.json();
};
const postCulture = async (data) => {
  const apiRest = await fetch(`${BASE_URL}/planification/culture`, {
    method: "POST",
    body: data,
  });
  return apiRest.json();
};
const updateCulture = async ({ data, id }) => {
  const apiRest = await fetch(`${BASE_URL}/planification/culture?id=${id}`, {
    method: "PATCH",
    body: data,
  });
  return apiRest.json();
};

const deleteCulture = async (id) => {
  const apiRest = await fetch(`${BASE_URL}/planification/culture?id=${id}`, {
    method: "DELETE",
    body: {},
  });
  return apiRest.json();
};

const postChampFixe = async (data) => {
  const apiRest = await fetch(`${BASE_URL}/planification/field-fixe`, {
    method: "POST",
    body: data,
  });
  return apiRest.json();
};
const updateChampFixe = async ({ data, id }) => {
  const apiRest = await fetch(`${BASE_URL}/planification/field-fixe?id=${id}`, {
    method: "PATCH",
    body: data,
  });
  return apiRest.json();
};
const deleteChampFixe = async (id) => {
  const apiRest = await fetch(`${BASE_URL}/planification/field-fixe?id=${id}`, {
    method: "DELETE",
    body: {},
  });
  return apiRest.json();
};

const fetchChampsVariableList = async ({ queryKey }) => {
  const [_, lang, page] = queryKey;
  if (!lang || !page) return [];
  const apiRest = await fetch(
    `${BASE_URL}/planification/field-variable?lang=${lang}&page=${page}`
  );
  return apiRest.json();
};
const postChampVariable = async (data) => {
  const apiRest = await fetch(`${BASE_URL}/planification/field-variable`, {
    method: "POST",
    body: data,
  });
  return apiRest.json();
};
const updateChampVariable = async ({ data, id }) => {
  const apiRest = await fetch(
    `${BASE_URL}/planification/field-variable?id=${id}`,
    {
      method: "PATCH",
      body: data,
    }
  );
  return apiRest.json();
};
const deleteChampVariable = async (id) => {
  const apiRest = await fetch(
    `${BASE_URL}/planification/field-variable?id=${id}`,
    {
      method: "DELETE",
      body: {},
    }
  );
  return apiRest.json();
};
const fetchAllFieldsAndCultureNoPage = async ({ queryKey }) => {
  const lang = queryKey[1];
  if (!lang) return [];
  const apiRest = await fetch(
    `${BASE_URL}/planification/all-fields-and-cultures?lang=${lang}`
  );
  return apiRest.json();
};
export {
  fetchProjetsList,
  fetchCategoryProjectList,
  fetchCultureList,
  fetchChampsFixeList,
  fetchProjetDetail,
  postCulture,
  updateCulture,
  deleteCulture,
  postChampFixe,
  updateChampFixe,
  deleteChampFixe,
  postCategoryProject,
  updateCategoryProject,
  deleteCategoryProject,
  fetchCategoryProjectListNoPage,
  fetchChampsVariableList,
  postChampVariable,
  updateChampVariable,
  deleteChampVariable,
  fetchAllFieldsAndCultureNoPage,
};
