const BASE_URL = process.env.REACT_APP_URL_API;
const fetchCategoriesFournisseurs = async ({ queryKey }) => {
  const [_, lang, page] = queryKey;
  if (!lang || !page) return [];
  const apiRest = await fetch(
    `${BASE_URL}/service/categorie?lang=${lang}&page=${page}`
  );
  return apiRest.json();
};
const fetchCategoriesFournisseursNoPage = async ({ queryKey }) => {
  const lang = queryKey[1];
  if (!lang) return [];
  const apiRest = await fetch(`${BASE_URL}/service/categorie-all?lang=${lang}`);
  return apiRest.json();
};
const postCategoriesFournisseur = async (data) => {
  const apiRest = await fetch(`${BASE_URL}/service/categorie`, {
    method: "POST",
    body: data,
  });
  return apiRest.json();
};
const updateCategorieFournisseur = async ({ data, id }) => {
  console.log(data, id);
  const apiRest = await fetch(`${BASE_URL}/service/categorie?id=${id}`, {
    method: "PATCH",
    body: data,
  });
  return apiRest.json();
};
const deleteCategoreFournisseur = async (id) => {
  const apiRest = await fetch(`${BASE_URL}/service/categorie?id=${id}`, {
    method: "DELETE",
    body: {},
  });
  return apiRest.json();
};

const fetchFournisseurs = async ({ queryKey }) => {
  const [_, lang, page] = queryKey;
  if (!lang || !page) return [];
  const apiRest = await fetch(
    `${BASE_URL}/service/fournisseur?lang=${lang}&page=${page}`
  );
  return apiRest.json();
};

const postFournisseur = async (data) => {
  const apiRest = await fetch(`${BASE_URL}/service/fournisseur`, {
    method: "POST",
    body: data,
  });
  return apiRest.json();
};

const updateFournisseur = async ({ data, id }) => {
  const apiRest = await fetch(`${BASE_URL}/service/fournisseur?id=${id}`, {
    method: "PATCH",
    body: data,
  });
  return apiRest.json();
};
const deleteFournisseur = async (id) => {
  const apiRest = await fetch(`${BASE_URL}/service/fournisseur?id=${id}`, {
    method: "DELETE",
    body: {},
  });
  return apiRest.json();
};

const fetchOneFournisseurWithServices = async ({ queryKey }) => {
  const { fId, lang, page } = queryKey[1];
  if (!lang || !page || !fId) return [];
  const apiRest = await fetch(
    `${BASE_URL}/service/fournisseur-service?lang=${lang}&page=${page}&fId=${fId}`
  );
  return apiRest.json();
};
const postServiceFournisseur = async (data) => {
  const apiRest = await fetch(`${BASE_URL}/service`, {
    method: "POST",
    body: data,
  });
  return apiRest.json();
};
const updateServiceFournisseur = async ({ data, id }) => {
  const apiRest = await fetch(`${BASE_URL}/service?id=${id}`, {
    method: "PATCH",
    body: data,
  });
  return apiRest.json();
};

const deleteServiceFournisseur = async (id) => {
  const apiRest = await fetch(`${BASE_URL}/service?id=${id}`, {
    method: "DELETE",
    body: {},
  });
  return apiRest.json();
};
export {
  fetchFournisseurs,
  fetchCategoriesFournisseurs,
  postCategoriesFournisseur,
  updateCategorieFournisseur,
  deleteCategoreFournisseur,
  fetchCategoriesFournisseursNoPage,
  postFournisseur,
  updateFournisseur,
  deleteFournisseur,
  fetchOneFournisseurWithServices,
  postServiceFournisseur,
  updateServiceFournisseur,
  deleteServiceFournisseur,
};
