const { useState, useEffect } = require("react");

const useGetProvinces = (data) => {
  const [provinces, setProvinces] = useState([]);
  useEffect(() => {
    if (data?.provinces) {
      const provinces = data.provinces.map((item) => {
        return {
          value: item.id,
          label: item.name,
        };
      });
      setProvinces(provinces);
    }
  }, [data]);

  return [provinces];
};

const useGetCommunes = (data, formik) => {
  const [communes, setCommunes] = useState([]);
  useEffect(() => {
    if (data?.communes) {
      const communes = data.communes.filter(
        (item) => item.provinceId === formik.values["provinceId"]
      );
      const newCommunes = communes.map((item) => {
        return {
          value: item.id,
          label: item.name,
        };
      });
      if (formik.dirty) {
        formik.values["communeId"] = "";
        formik.values["zoneId"] = "";
        formik.values["collineId"] = "";
        formik.values["localiteId"] = "";
      }
      setCommunes(newCommunes);
    }
  }, [formik.values["provinceId"]]);

  return [communes];
};

const useGetZones = (data, formik) => {
  const [zones, setZones] = useState([]);
  useEffect(() => {
    if (data?.zones) {
      const zones = data.zones.filter(
        (item) => item.communeId === formik.values["communeId"]
      );
      const newZones = zones.map((item) => {
        return {
          value: item.id,
          label: item.name,
        };
      });
      if (formik.dirty) {
        formik.values["zoneId"] = "";
        formik.values["collineId"] = "";
        formik.values["localiteId"] = "";
      }

      setZones(newZones);
    }
  }, [formik.values["communeId"]]);

  return [zones];
};

const useGetCollines = (data, formik) => {
  const [collines, setCollines] = useState([]);
  useEffect(() => {
    if (data?.collines) {
      const collines = data.collines.filter(
        (item) => item.zoneId === formik.values["zoneId"]
      );
      const newCollines = collines.map((item) => {
        return {
          value: item.id,
          label: item.name,
        };
      });
      if (formik.dirty) {
        formik.values["collineId"] = "";
        formik.values["localiteId"] = "";
      }
      setCollines(newCollines);
    }
  }, [formik.values["zoneId"]]);

  return [collines];
};

const useGetLocalites = (data, formik) => {
  const [localites, setLocalites] = useState([]);
  useEffect(() => {
    if (data?.localite) {
      const localite = data.localite.filter(
        (item) => item.collineId === formik.values["collineId"]
      );
      const newLocalites = localite.map((item) => {
        return {
          value: item.id,
          label: item.name,
        };
      });
      if (formik.dirty) {
        formik.values["localiteId"] = "";
      }
      setLocalites(newLocalites);
    }
  }, [formik.values["collineId"]]);

  return [localites];
};
export {
  useGetProvinces,
  useGetCommunes,
  useGetZones,
  useGetCollines,
  useGetLocalites,
};
