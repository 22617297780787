const BASE_URL = process.env.REACT_APP_URL_API;
const fetchAcademieListCategory = async ({ queryKey }) => {
  const lang = queryKey[1];
  const page = queryKey[2];
  if (!lang || !page) return [];
  const apiRest = await fetch(
    `${BASE_URL}/academie/categorie?lang=${lang}&page=${page}`
  );
  return apiRest.json();
};

const fetchAcademieOneCategory = async ({ queryKey }) => {
  const { id, lang, page } = queryKey[1];

  if (!lang || !id || !page) return [];
  const apiRest = await fetch(
    `${BASE_URL}/academie/one-categorie?lang=${lang}&id=${id}&page=${page}`
  );
  return apiRest.json();
};

const postAcademieCategory = async (data) => {
  const apiRest = await fetch(`${BASE_URL}/academie/categorie`, {
    method: "POST",
    body: data,
  });
  return apiRest.json();
};
const updateAcademieCategory = async ({ data, id }) => {
  const apiRest = await fetch(`${BASE_URL}/academie/categorie?id=${id}`, {
    method: "PATCH",
    body: data,
  });
  return apiRest.json();
};

const deleteAcademieCategory = async (id) => {
  const apiRest = await fetch(`${BASE_URL}/academie/categorie?id=${id}`, {
    method: "DELETE",
    body: {},
  });
  return apiRest.json();
};

const postAcademieVideo = async (data) => {
  const apiRest = await fetch(`${BASE_URL}/academie/video`, {
    method: "POST",
    body: data,
  });
  return apiRest.json();
};
const updateAcademieVideo = async ({ data, id }) => {
  const apiRest = await fetch(`${BASE_URL}/academie/video?id=${id}`, {
    method: "PATCH",
    body: data,
  });
  return apiRest.json();
};
const deleteAcademieVideo = async (id) => {
  const apiRest = await fetch(`${BASE_URL}/academie/video?id=${id}`, {
    method: "DELETE",
    body: {},
  });
  return apiRest.json();
};

const fetchAcademieQuestionCategory = async ({ queryKey }) => {
  const { catId, lang, page } = queryKey[1];

  if (!lang || !catId || !page) return [];
  const apiRest = await fetch(
    `${BASE_URL}/academie/category-questions?lang=${lang}&catId=${catId}&page=${page}`
  );
  return apiRest.json();
};

const postAcademieQuestion = async (data) => {
  const apiRest = await fetch(`${BASE_URL}/question`, {
    method: "POST",
    body: data,
  });
  return apiRest.json();
};

const updateAcademieQuestion = async ({ data, id }) => {
  const apiRest = await fetch(`${BASE_URL}/question?id=${id}`, {
    method: "PATCH",
    body: data,
  });
  return apiRest.json();
};

const deleteAcademieQuestion = async (id) => {
  const apiRest = await fetch(`${BASE_URL}/question?id=${id}`, {
    method: "DELETE",
    body: {},
  });
  return apiRest.json();
};

export {
  fetchAcademieListCategory,
  postAcademieCategory,
  updateAcademieCategory,
  deleteAcademieCategory,
  fetchAcademieOneCategory,
  postAcademieVideo,
  updateAcademieVideo,
  deleteAcademieVideo,
  fetchAcademieQuestionCategory,
  postAcademieQuestion,
  updateAcademieQuestion,
  deleteAcademieQuestion,
};
