import { useQuery } from "@tanstack/react-query";
import {
  fetchFournisseurs,
  fetchCategoriesFournisseurs,
  fetchCategoriesFournisseursNoPage,
  fetchOneFournisseurWithServices,
} from "services/serviceApi";

const useQuerieCategorieFournisseur = (lang, page) => {
  const { data, isPending, isFetching } = useQuery({
    queryKey: ["categorie-fournisseurs", lang, page],
    queryFn: fetchCategoriesFournisseurs,
  });
  return [
    { data: data?.data.rows ?? [], dataCount: data?.data.count ?? 0 },
    isFetching,
  ];
};
const useQueryCategorieFournisseurNoPage = (lang) => {
  const { data, isPending, isFetching } = useQuery({
    queryKey: ["all-categorie-fournisseurs", lang],
    queryFn: fetchCategoriesFournisseursNoPage,
  });
  return [data?.data ?? [], isFetching];
};

const useQuerieFournisseur = (lang, page) => {
  const { data, isPending, isFetching } = useQuery({
    queryKey: ["fournisseurs", lang, page],
    queryFn: fetchFournisseurs,
  });
  return [
    { data: data?.data.rows ?? [], dataCount: data?.data.count ?? 0 },
    isFetching,
  ];
};

const useQueryFournisseurWithServices = (params) => {
  const { data, isPending, isFetching } = useQuery({
    queryKey: ["fournisseurs-with-services", params],
    queryFn: fetchOneFournisseurWithServices,
  });
  return [data?.data ?? [], isFetching];
};
export {
  useQuerieFournisseur,
  useQuerieCategorieFournisseur,
  useQueryCategorieFournisseurNoPage,
  useQueryFournisseurWithServices,
};
