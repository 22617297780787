import { Space, Spin, theme } from "antd";
import { PROJET_CONFIG } from "constant";
const LoadingSuspense = () => {
  const {
    token: { colorPrimary },
  } = theme.useToken();
  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        backgroundColor: colorPrimary,
      }}
    >
      <Space
        direction="vertical"
        style={{ justifyContent: "center" }}
        align="center"
      >
        <Spin
          size={"large"}
          fullscreen={true}
          tip={PROJET_CONFIG.appLongName}
        />
      </Space>
    </div>
  );
};

export default LoadingSuspense;
