import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  dataUser: {},
  token: "",
  isConnected: false,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setLoginUser: (state, { payload }) => {
      const { dataUser, isConnected, token } = payload;
      state.dataUser = dataUser;
      state.isConnected = isConnected;
      state.token = token;
    },
    setLogoutUser: (state) => {
      state.dataUser = {};
      state.isConnected = false;
      state.token = "";
    },
  },
});

export const { setLoginUser, setLogoutUser } = userSlice.actions;
export default userSlice.reducer;
