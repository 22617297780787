import { useContext } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setLoginUser } from "../redux";

import { NotificationBoxContext } from "context";
import { loginUser } from "services/authApi";
import {
  postUsageVideo,
  deleteUsageVideo,
  updateUsageVideo,
} from "services/usageApi";
import {
  postAcademieCategory,
  deleteAcademieCategory,
  updateAcademieCategory,
  postAcademieVideo,
  updateAcademieVideo,
  deleteAcademieVideo,
  postAcademieQuestion,
  updateAcademieQuestion,
  deleteAcademieQuestion,
} from "services/academieApi";

import {
  postCulture,
  updateCulture,
  deleteCulture,
  postCategoryProject,
  updateCategoryProject,
  deleteCategoryProject,
  postChampFixe,
  updateChampFixe,
  deleteChampFixe,
  postChampVariable,
  updateChampVariable,
  deleteChampVariable,
} from "services/planificationApi";

import {
  postCategoriesFournisseur,
  updateCategorieFournisseur,
  deleteCategoreFournisseur,
  postFournisseur,
  updateFournisseur,
  deleteFournisseur,
  postServiceFournisseur,
  updateServiceFournisseur,
  deleteServiceFournisseur,
} from "services/serviceApi";

const usePostAuthenticationUser = () => {
  const openNotification = useContext(NotificationBoxContext);
  const navigateTo = useNavigate();
  const dispatch = useDispatch();
  return useMutation({
    mutationFn: loginUser,
    onSuccess: (data) => {
      const { status, msg } = data;
      if (status === "failed") {
        openNotification({ status, msg });
      }
      if (status === "success") {
        const userAuth = data.data;
        dispatch(
          setLoginUser({
            dataUser: userAuth.data,
            isConnected: true,
            token: userAuth.token,
          })
        );
        return navigateTo("/dashboard");
      }
    },
  });
};

// USAGES MUTATIONS : CREATE, UPDATE, DELETE

const usePostUsageVideo = () => {
  const queryClient = useQueryClient();
  const openNotification = useContext(NotificationBoxContext);
  return useMutation({
    mutationFn: postUsageVideo,
    onSuccess: (data) => {
      const { status, msg } = data;
      if (status === "success") {
        queryClient.invalidateQueries(["usages-videos"]);
      }
      openNotification({ status, msg });
    },
    onError: () => {},
  });
};

const useUpdateUsageVideo = () => {
  const queryClient = useQueryClient();
  const openNotification = useContext(NotificationBoxContext);
  return useMutation({
    mutationFn: updateUsageVideo,
    onSuccess: (data) => {
      const { status, msg } = data;
      if (status === "success") {
        queryClient.invalidateQueries(["usages-videos"]);
      }
      openNotification({ status, msg });
    },
    onError: () => {},
  });
};

const useDeleteUsageVideo = () => {
  const queryClient = useQueryClient();
  const openNotification = useContext(NotificationBoxContext);
  return useMutation({
    mutationFn: deleteUsageVideo,
    onSuccess: (data) => {
      queryClient.invalidateQueries(["usages-videos"]);
      const { status, msg } = data;
      openNotification({ status, msg });
    },
    onError: () => {},
  });
};

// ACADEMIE MUTATIONS
const usePostAcademieCategory = () => {
  const queryClient = useQueryClient();
  const openNotification = useContext(NotificationBoxContext);
  return useMutation({
    mutationFn: postAcademieCategory,
    onSuccess: (data) => {
      const { status, msg } = data;
      if (status === "success") {
        queryClient.invalidateQueries(["academie-list-categories"]);
      }
      openNotification({ status, msg });
    },
    onError: () => {},
  });
};
const useDeleteAcademieCategory = () => {
  const queryClient = useQueryClient();
  const openNotification = useContext(NotificationBoxContext);
  return useMutation({
    mutationFn: deleteAcademieCategory,
    onSuccess: (data) => {
      queryClient.invalidateQueries(["academie-list-categories"]);
      const { status, msg } = data;
      openNotification({ status, msg });
    },
    onError: () => {},
  });
};
const useUpdateAcademieCategory = () => {
  const queryClient = useQueryClient();
  const openNotification = useContext(NotificationBoxContext);
  return useMutation({
    mutationFn: updateAcademieCategory,
    onSuccess: (data) => {
      const { status, msg } = data;
      if (status === "success") {
        queryClient.invalidateQueries(["academie-list-categories"]);
        queryClient.invalidateQueries(["category-with-videos"]);
      }
      openNotification({ status, msg });
    },
    onError: () => {},
  });
};
const usePostAcademieVideo = () => {
  const queryClient = useQueryClient();
  const openNotification = useContext(NotificationBoxContext);
  return useMutation({
    mutationFn: postAcademieVideo,
    onSuccess: (data) => {
      const { status, msg } = data;
      if (status === "success") {
        queryClient.invalidateQueries(["category-with-videos"]);
      }
      openNotification({ status, msg });
    },
    onError: () => {},
  });
};
const useUpdateAcademieVideo = () => {
  const queryClient = useQueryClient();
  const openNotification = useContext(NotificationBoxContext);
  return useMutation({
    mutationFn: updateAcademieVideo,
    onSuccess: (data) => {
      const { status, msg } = data;
      if (status === "success") {
        queryClient.invalidateQueries(["category-with-videos"]);
      }
      openNotification({ status, msg });
    },
    onError: () => {},
  });
};

const useDeleteAcademieVideo = () => {
  const queryClient = useQueryClient();
  const openNotification = useContext(NotificationBoxContext);
  return useMutation({
    mutationFn: deleteAcademieVideo,
    onSuccess: (data) => {
      queryClient.invalidateQueries(["category-with-videos"]);
      const { status, msg } = data;
      openNotification({ status, msg });
    },
    onError: () => {},
  });
};

const usePostAcademieQuestion = () => {
  const queryClient = useQueryClient();
  const openNotification = useContext(NotificationBoxContext);
  return useMutation({
    mutationFn: postAcademieQuestion,
    onSuccess: (data) => {
      const { status, msg } = data;
      if (status === "success") {
        queryClient.invalidateQueries(["category-with-questions"]);
      }
      openNotification({ status, msg });
    },
    onError: () => {},
  });
};
const useUpdateAcademieQuestion = () => {
  const queryClient = useQueryClient();
  const openNotification = useContext(NotificationBoxContext);
  return useMutation({
    mutationFn: updateAcademieQuestion,
    onSuccess: (data) => {
      const { status, msg } = data;
      if (status === "success") {
        queryClient.invalidateQueries(["category-with-questions"]);
      }
      openNotification({ status, msg });
    },
    onError: () => {},
  });
};
const useDeleteAcademieQuestion = () => {
  const queryClient = useQueryClient();
  const openNotification = useContext(NotificationBoxContext);
  return useMutation({
    mutationFn: deleteAcademieQuestion,
    onSuccess: (data) => {
      queryClient.invalidateQueries(["category-with-questions"]);
      const { status, msg } = data;
      openNotification({ status, msg });
    },
    onError: () => {},
  });
};

// SERVICES MUTATIONS
const usePostCategoryFournisseur = () => {
  const queryClient = useQueryClient();
  const openNotification = useContext(NotificationBoxContext);
  return useMutation({
    mutationFn: postCategoriesFournisseur,
    onSuccess: (data) => {
      const { status, msg } = data;
      if (status === "success") {
        queryClient.invalidateQueries(["categorie-fournisseurs"]);
      }
      openNotification({ status, msg });
    },
    onError: () => {},
  });
};
const useUpdateCategoryFournisseur = () => {
  const queryClient = useQueryClient();
  const openNotification = useContext(NotificationBoxContext);
  return useMutation({
    mutationFn: updateCategorieFournisseur,
    onSuccess: (data) => {
      const { status, msg } = data;
      if (status === "success") {
        queryClient.invalidateQueries(["categorie-fournisseurs"]);
      }
      openNotification({ status, msg });
    },
    onError: () => {},
  });
};
const useDeleteCategorieFournisseur = () => {
  const queryClient = useQueryClient();
  const openNotification = useContext(NotificationBoxContext);
  return useMutation({
    mutationFn: deleteCategoreFournisseur,
    onSuccess: (data) => {
      queryClient.invalidateQueries(["categorie-fournisseurs"]);
      const { status, msg } = data;
      openNotification({ status, msg });
    },
    onError: () => {},
  });
};
const usePostServiceFournisseur = () => {
  const queryClient = useQueryClient();
  const openNotification = useContext(NotificationBoxContext);
  return useMutation({
    mutationFn: postFournisseur,
    onSuccess: (data) => {
      const { status, msg } = data;
      if (status === "success") {
        queryClient.invalidateQueries(["fournisseurs"]);
      }
      openNotification({ status, msg });
    },
    onError: () => {},
  });
};
const useUpdateFournisseur = () => {
  const queryClient = useQueryClient();
  const openNotification = useContext(NotificationBoxContext);
  return useMutation({
    mutationFn: updateFournisseur,
    onSuccess: (data) => {
      const { status, msg } = data;
      if (status === "success") {
        queryClient.invalidateQueries(["fournisseurs"]);
      }
      openNotification({ status, msg });
    },
    onError: () => {},
  });
};
const useDeleteFournisseur = () => {
  const queryClient = useQueryClient();
  const openNotification = useContext(NotificationBoxContext);
  return useMutation({
    mutationFn: deleteFournisseur,
    onSuccess: (data) => {
      queryClient.invalidateQueries(["fournisseurs"]);
      const { status, msg } = data;
      openNotification({ status, msg });
    },
    onError: () => {},
  });
};

const usePostFournisseurService = () => {
  const queryClient = useQueryClient();
  const openNotification = useContext(NotificationBoxContext);
  return useMutation({
    mutationFn: postServiceFournisseur,
    onSuccess: (data) => {
      const { status, msg } = data;
      if (status === "success") {
        queryClient.invalidateQueries(["fournisseurs-with-services"]);
      }
      openNotification({ status, msg });
    },
    onError: () => {},
  });
};
const useUpdateFournisseurService = () => {
  const queryClient = useQueryClient();
  const openNotification = useContext(NotificationBoxContext);
  return useMutation({
    mutationFn: updateServiceFournisseur,
    onSuccess: (data) => {
      const { status, msg } = data;
      if (status === "success") {
        queryClient.invalidateQueries(["fournisseurs-with-services"]);
      }
      openNotification({ status, msg });
    },
    onError: () => {},
  });
};
const useDeleteFournisseurService = () => {
  const queryClient = useQueryClient();
  const openNotification = useContext(NotificationBoxContext);
  return useMutation({
    mutationFn: deleteServiceFournisseur,
    onSuccess: (data) => {
      queryClient.invalidateQueries(["fournisseurs-with-services"]);
      const { status, msg } = data;
      openNotification({ status, msg });
    },
    onError: () => {},
  });
};
//PLANIFICATION MUTATIONS

const usePostCategoryProjet = () => {
  const queryClient = useQueryClient();
  const openNotification = useContext(NotificationBoxContext);
  return useMutation({
    mutationFn: postCategoryProject,
    onSuccess: (data) => {
      const { status, msg } = data;
      if (status === "success") {
        queryClient.invalidateQueries(["categorie-projects"]);
      }
      openNotification({ status, msg });
    },
    onError: () => {},
  });
};
const useUpdateCategoryProjet = () => {
  const queryClient = useQueryClient();
  const openNotification = useContext(NotificationBoxContext);
  return useMutation({
    mutationFn: updateCategoryProject,
    onSuccess: (data) => {
      const { status, msg } = data;
      if (status === "success") {
        queryClient.invalidateQueries(["categorie-projects"]);
      }
      openNotification({ status, msg });
    },
    onError: () => {},
  });
};
const useDeleteCategoryProjet = () => {
  const queryClient = useQueryClient();
  const openNotification = useContext(NotificationBoxContext);
  return useMutation({
    mutationFn: deleteCategoryProject,
    onSuccess: (data) => {
      queryClient.invalidateQueries(["categorie-projects"]);
      const { status, msg } = data;
      openNotification({ status, msg });
    },
    onError: () => {},
  });
};
const usePostCulture = () => {
  const queryClient = useQueryClient();
  const openNotification = useContext(NotificationBoxContext);
  return useMutation({
    mutationFn: postCulture,
    onSuccess: (data) => {
      const { status, msg } = data;
      if (status === "success") {
        queryClient.invalidateQueries(["cultures"]);
      }
      openNotification({ status, msg });
    },
    onError: () => {},
  });
};
const useUpdateCulture = () => {
  const queryClient = useQueryClient();
  const openNotification = useContext(NotificationBoxContext);
  return useMutation({
    mutationFn: updateCulture,
    onSuccess: (data) => {
      const { status, msg } = data;
      if (status === "success") {
        queryClient.invalidateQueries(["cultures"]);
      }
      openNotification({ status, msg });
    },
    onError: () => {},
  });
};
const useDeleteCulture = () => {
  const queryClient = useQueryClient();
  const openNotification = useContext(NotificationBoxContext);
  return useMutation({
    mutationFn: deleteCulture,
    onSuccess: (data) => {
      queryClient.invalidateQueries(["cultures"]);
      const { status, msg } = data;
      openNotification({ status, msg });
    },
    onError: () => {},
  });
};
const usePostChampFixe = () => {
  const queryClient = useQueryClient();
  const openNotification = useContext(NotificationBoxContext);
  return useMutation({
    mutationFn: postChampFixe,
    onSuccess: (data) => {
      const { status, msg } = data;
      if (status === "success") {
        queryClient.invalidateQueries(["fields-fixe"]);
      }
      openNotification({ status, msg });
    },
    onError: () => {},
  });
};
const useUpdateChampFixe = () => {
  const queryClient = useQueryClient();
  const openNotification = useContext(NotificationBoxContext);
  return useMutation({
    mutationFn: updateChampFixe,
    onSuccess: (data) => {
      const { status, msg } = data;
      if (status === "success") {
        queryClient.invalidateQueries(["fields-fixe"]);
      }
      openNotification({ status, msg });
    },
    onError: () => {},
  });
};
const useDeleteChampFixe = () => {
  const queryClient = useQueryClient();
  const openNotification = useContext(NotificationBoxContext);
  return useMutation({
    mutationFn: deleteChampFixe,
    onSuccess: (data) => {
      queryClient.invalidateQueries(["fields-fixe"]);
      const { status, msg } = data;
      openNotification({ status, msg });
    },
    onError: () => {},
  });
};

const usePostChampVariable = () => {
  const queryClient = useQueryClient();
  const openNotification = useContext(NotificationBoxContext);
  return useMutation({
    mutationFn: postChampVariable,
    onSuccess: (data) => {
      const { status, msg } = data;
      if (status === "success") {
        queryClient.invalidateQueries(["fields-variable"]);
      }
      openNotification({ status, msg });
    },
    onError: () => {},
  });
};
const useUpdateChampVariable = () => {
  const queryClient = useQueryClient();
  const openNotification = useContext(NotificationBoxContext);
  return useMutation({
    mutationFn: updateChampVariable,
    onSuccess: (data) => {
      const { status, msg } = data;
      if (status === "success") {
        queryClient.invalidateQueries(["fields-variable"]);
      }
      openNotification({ status, msg });
    },
    onError: () => {},
  });
};

const useDeleteChampVariable = () => {
  const queryClient = useQueryClient();
  const openNotification = useContext(NotificationBoxContext);
  return useMutation({
    mutationFn: deleteChampVariable,
    onSuccess: (data) => {
      queryClient.invalidateQueries(["fields-fixe"]);
      const { status, msg } = data;
      openNotification({ status, msg });
    },
    onError: () => {},
  });
};
export {
  usePostAuthenticationUser,
  usePostUsageVideo,
  useDeleteUsageVideo,
  useUpdateUsageVideo,
  usePostAcademieCategory,
  useDeleteAcademieCategory,
  useUpdateAcademieCategory,
  usePostAcademieVideo,
  useUpdateAcademieVideo,
  useDeleteAcademieVideo,
  usePostAcademieQuestion,
  useUpdateAcademieQuestion,
  useDeleteAcademieQuestion,
  usePostCulture,
  useUpdateCulture,
  useDeleteCulture,
  usePostChampFixe,
  useUpdateChampFixe,
  useDeleteChampFixe,
  usePostCategoryFournisseur,
  useUpdateCategoryFournisseur,
  useDeleteCategorieFournisseur,
  usePostServiceFournisseur,
  useUpdateFournisseur,
  useDeleteFournisseur,
  usePostFournisseurService,
  useUpdateFournisseurService,
  useDeleteFournisseurService,
  usePostCategoryProjet,
  useUpdateCategoryProjet,
  useDeleteCategoryProjet,
  usePostChampVariable,
  useUpdateChampVariable,
  useDeleteChampVariable,
};
