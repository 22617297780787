import { useQuery } from "@tanstack/react-query";
import { fetchAllStatiqueAddressData } from "services/statiqueDataApi";
const useQueryStatiqueAddressData = (params) => {
  const { data, isPending, isFetching } = useQuery({
    queryKey: ["statique-address-data", params],
    queryFn: fetchAllStatiqueAddressData,
  });
  return [data?.data ?? {}, isFetching];
};

export { useQueryStatiqueAddressData };
