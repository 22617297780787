import { useQuery } from "@tanstack/react-query";
import { fetchRapport } from "services/rapportsApi";
const useQueryRapport = (params) => {
  const { data, isPending, refetch, isFetching } = useQuery({
    queryKey: ["rapport", params],
    queryFn: fetchRapport,
    enabled: false,
  });
  return [data?.data ?? [], isFetching, refetch];
};

export { useQueryRapport };
