import { useEffect, useContext } from "react";
import { BreadcumbContext } from "context";
const useBreadcumb = (BREADCRUMB) => {
  const setBreadcumb = useContext(BreadcumbContext)[1];
  useEffect(() => {
    setBreadcumb(BREADCRUMB);
  }, []);
};

export default useBreadcumb;
