import { useQuery } from "@tanstack/react-query";
import {
  fetchProjetsList,
  fetchCultureList,
  fetchChampsFixeList,
  fetchProjetDetail,
  fetchAllFieldsAndVarietes,
  fetchCategoryProjectList,
  fetchChampsVariableList,
} from "services/planificationApi";

const useQueryProjectList = (lang, page) => {
  const { data, isPending } = useQuery({
    queryKey: ["projets", lang, page],
    queryFn: fetchProjetsList,
  });

  return [data?.data ?? [], isPending];
};
const useQueryCulturesList = (lang, page) => {
  const { data, isPending } = useQuery({
    queryKey: ["cultures", lang, page],
    queryFn: fetchCultureList,
  });

  return [data?.data, isPending];
};
const useQueryChampsFixeList = (lang, page) => {
  const { data, isPending } = useQuery({
    queryKey: ["fields-fixe", lang, page],
    queryFn: fetchChampsFixeList,
  });
  return [data?.data ?? [], isPending];
};
const useQueryProjetDetail = (id) => {
  const { data, isFetching } = useQuery({
    queryKey: ["projet-detail", id],
    queryFn: fetchProjetDetail,
  });
  return [data?.data ?? [], isFetching];
};

const useQueryCategoriesProjectList = (lang, page) => {
  const { data, isPending } = useQuery({
    queryKey: ["categorie-projects", lang, page],
    queryFn: fetchCategoryProjectList,
  });
  return [data?.data, isPending];
};
const useQueryChampsVariableList = (lang, page) => {
  const { data, isPending } = useQuery({
    queryKey: ["fields-variable", lang, page],
    queryFn: fetchChampsVariableList,
  });
  return [data?.data, isPending];
};

export {
  useQueryProjectList,
  useQueryCulturesList,
  useQueryChampsFixeList,
  useQueryProjetDetail,
  useQueryChampsVariableList,
  useQueryCategoriesProjectList,
};
