import React, { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import { LoadingSuspense, PrivateRoute } from "utils";

const LoginPage = lazy(() => import("./pages/auths/LoginPage"));
const DashboardPage = lazy(() => import("./pages/dashboard/DashboardPage"));
const UsagePage = lazy(() => import("./pages/usages/UsagePage"));
const UsageDetailPage = lazy(() => import("./pages/usages/UsageDetailPage"));
const AcademiePage = lazy(() => import("./pages/academie/AcademiePage"));
const AcademieQuestionPage = lazy(() =>
  import("./pages/academie/AcademieQuestionPage")
);
const AcademieVideoPage = lazy(() =>
  import("./pages/academie/AcademieVideoPage")
);
const RapportPage = lazy(() => import("./pages/rapport/RapportPage"));
const ServicePage = lazy(() => import("./pages/services/ServicePage"));
const ServiceVideoPage = lazy(() =>
  import("./pages/services/ServiceVideoPage")
);
const PlanificationPage = lazy(() =>
  import("./pages/planifications/PlanificationPage")
);
const ErrorPage404 = lazy(() => import("./pages/error/ErrorPage404"));

const App = () => {
  return (
    <Suspense fallback={<LoadingSuspense />}>
      <Routes>
        <Route element={<PrivateRoute />}>
          <Route path="/dashboard" element={<DashboardPage />} />
          <Route path="/usage" element={<UsagePage />} />
          <Route path="/usage-video-detail/:id" element={<UsageDetailPage />} />
          <Route path="/academie" element={<AcademiePage />} />
          <Route path="/academie-videos/:id" element={<AcademieVideoPage />} />
          <Route
            path="/academie-questions/:id"
            element={<AcademieQuestionPage />}
          />
          <Route path="/services" element={<ServicePage />}>
            {["fournisseurs", "categorie-fournisseur"].map((path) => (
              <Route key={path} path={path} element={<ServicePage />} />
            ))}
          </Route>
          <Route
            path="/services-fournisseurs-videos/:id"
            element={<ServiceVideoPage />}
          />
          <Route path="/planifications" element={<PlanificationPage />}>
            {[
              "projet",
              "categorie-projet",
              "cultures",
              "config-fields-fixed",
              "config-fields-variables",
              "projet-detail/:id",
            ].map((path) => (
              <Route key={path} path={path} element={<PlanificationPage />} />
            ))}
          </Route>
          <Route path="/rapports" element={<RapportPage />} />
          <Route path="*" element={<ErrorPage404 />} />
        </Route>

        <Route path="/" element={<LoginPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="*" element={<ErrorPage404 />} />
      </Routes>
    </Suspense>
  );
};

export default App;
