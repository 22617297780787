const FILTRE_RAPPORT = {
  form: {
    vertical: false,
    space: {
      direction: "horizital",
      size: "middle",
    },
  },
  fields: [
    {
      type: "select",
      direction: "vertical",
      options: [
        {
          value: 1,
          label: "Rapport d'installation",
        },
        {
          value: 2,
          label: "Rapport de connexion",
        },
        {
          value: 3,
          label: "Rapport des synchronisations",
        },
        {
          value: 4,
          label: "Rapport de la visualisation des vidéos",
        },
        {
          value: 5,
          label: "Rapport des réponses aux questions",
        },
        {
          value: 6,
          label: "Rapport des projets synchronisés",
        },
      ],
      width: "200px",
      name: "typeRapportId",
      label: "Type de rapport",
    },
    {
      type: "date",
      direction: "vertical",
      width: "200px",
      label: "Date Debut",
      name: "dateDebut",
      placeholder: "",
      format: "YYYY-MM-DD",
    },
    {
      type: "date",
      direction: "vertical",
      width: "200px",
      label: "Date Fin",
      name: "dateFin",
      placeholder: "",
      format: "YYYY-MM-DD",
    },
  ],
};
const LOGIN_FORM = {
  form: {
    vertical: true,
    space: {
      direction: "vertical",
      size: "middle",
    },
  },
  fields: [
    {
      type: "input",
      direction: "vertical",
      label: "Nom d'utilisateur",
      name: "username",
      placeholder: "Nom d'utilisateur",
      width: "100%",
      secured: false,
    },
    {
      type: "input",
      direction: "vertical",
      label: "Mot de passe",
      name: "password",
      placeholder: "Mot de passe",
      width: "100%",
      secured: true,
    },
  ],
};
const ADD_VIDEO_USAGE_FORM = {
  form: {
    vertical: true,
    space: {
      direction: "vertical",
      size: "middle",
    },
  },
  fields: [
    {
      type: "input",
      direction: "vertical",
      label: "Titre de la vidéo",
      name: "title",
      placeholder: "Titre",
      width: "100%",
      secured: false,
    },
    {
      type: "textarea",
      direction: "vertical",
      label: "Déscription",
      name: "description",
      placeholder: "Tapez ici...",
      width: "100%",
    },
    {
      type: "file",
      direction: "vertical",
      label: "Photo Poster",
      name: "image",
      placeholder: "Séléctionné l'image poster",
      width: "100%",
      acceptList: ".png,.jpeg,.jpg",
      acceptMultipleFile: false,
    },
    {
      type: "file",
      direction: "vertical",
      label: "Video d'utilisation",
      name: "video",
      placeholder: "Séléctionné la video",
      width: "100%",
      acceptList: ".mov,.mp4,.avi,.vmf,.flv,.webm,.m4v,.x-m4v",
      acceptMultipleFile: false,
    },
  ],
};
const ADD_VIDEO_USAGE_OTHER_LANGUAGE_FORM = {
  form: {
    vertical: true,
    space: {
      direction: "vertical",
      size: "middle",
    },
  },
  fields: [
    {
      type: "select",
      direction: "vertical",
      options: [],
      width: "200px",
      name: "lang",
      label: "Langue",
    },
    {
      type: "input",
      direction: "vertical",
      label: "Titre de la vidéo",
      name: "title",
      placeholder: "Titre",
      width: "100%",
      secured: false,
    },
    {
      type: "textarea",
      direction: "vertical",
      label: "Déscription",
      name: "description",
      placeholder: "Tapez ici...",
      width: "100%",
    },
  ],
};
const ADD_EDUCATION_CATEGORY_FROM = {
  form: {
    vertical: true,
    space: {
      direction: "vertical",
      size: "middle",
    },
  },
  fields: [
    {
      type: "input",
      direction: "vertical",
      label: "Description",
      name: "description",
      placeholder: "Description",
      width: "100%",
      secured: false,
    },
    {
      type: "radio",
      direction: "vertical",
      name: "have_question",
      label: "Possède de question ?",
      width: "100%",
      options: [
        {
          value: 1,
          name: "OUI",
        },
        {
          value: 0,
          name: "NON",
        },
      ],
    },
    {
      type: "file",
      direction: "vertical",
      label: "Image Poster",
      name: "icone",
      placeholder: "Séléctionné l'image ou une icone",
      width: "100%",
      acceptList: ".png,.jpeg,.jpg",
      acceptMultipleFile: false,
    },
  ],
};
const ADD_EDUCATION_CATEGORY_OTHER_LANGUAGE_FORM = {
  form: {
    vertical: true,
    space: {
      direction: "vertical",
      size: "middle",
    },
  },
  fields: [
    {
      type: "select",
      direction: "vertical",
      options: [],
      width: "200px",
      name: "lang",
      label: "Langue",
    },
    {
      type: "input",
      direction: "vertical",
      label: "Description",
      name: "description",
      placeholder: "Description",
      width: "100%",
      secured: false,
    },
  ],
};
const ADD_VIDEO_EDUCATION_FORM = {
  form: {
    vertical: true,
    space: {
      direction: "vertical",
      size: "middle",
    },
  },
  fields: [
    {
      type: "input",
      direction: "vertical",
      label: "Titre de la vidéo",
      name: "title",
      placeholder: "Titre",
      width: "100%",
      secured: false,
    },
    {
      type: "file",
      direction: "vertical",
      label: "Photo Poster",
      name: "image",
      placeholder: "Séléctionné l'image poster",
      width: "100%",
      acceptList: ".png,.jpeg,.jpg",
      acceptMultipleFile: false,
    },
    {
      type: "file",
      direction: "vertical",
      label: "Video formation",
      name: "video",
      placeholder: "Séléctionné la video",
      width: "100%",
      acceptList: ".mov,.mp4,.avi,.vmf,.flv,.webm,.m4v,.x-m4v",
      acceptMultipleFile: false,
    },
  ],
};
const ADD_VIDEO_EDUCATION_OTHER_LANGUAGE_FORM = {
  form: {
    vertical: true,
    space: {
      direction: "vertical",
      size: "middle",
    },
  },
  fields: [
    {
      type: "select",
      direction: "vertical",
      options: [],
      width: "200px",
      name: "lang",
      label: "Langue",
    },
    {
      type: "input",
      direction: "vertical",
      label: "Titre de la vidéo",
      name: "title",
      placeholder: "Titre",
      width: "100%",
      secured: false,
    },
  ],
};
const ADD_QUESTION_EDUCATION_FORM = {
  form: {
    vertical: true,
    space: {
      direction: "vertical",
      size: "middle",
    },
  },
  fields: [
    {
      type: "select",
      direction: "vertical",
      options: [
        { value: 1, label: "Reflexion" },
        { value: 2, label: "Evaluation" },
      ],
      width: "200px",
      name: "typeQuestion",
      label: "Type de question",
    },
  ],
};
const ADD_QUESTION_REFLEXION_EDUCATION_FORM = {
  form: {
    vertical: true,
    space: {
      direction: "vertical",
      size: "middle",
    },
  },
  fields: [
    {
      type: "textarea",
      direction: "vertical",
      label: "Question",
      name: "question",
      placeholder: "Tapez ici...",
      width: "100%",
    },

    {
      type: "file",
      direction: "vertical",
      label: "Audio Voif Off",
      name: "audio",
      placeholder: "Séléctionné un audio",
      width: "100%",
      acceptList: ".mp3",
      acceptMultipleFile: false,
    },
  ],
};
const ADD_QUESTION_EVALUATION_EDUCATION_FORM = {
  form: {
    vertical: true,
    space: {
      direction: "vertical",
      size: "middle",
    },
  },
  fields: [
    {
      type: "textarea",
      direction: "vertical",
      label: "Question",
      name: "question",
      placeholder: "Tapez ici...",
      width: "100%",
    },
    {
      type: "select",
      direction: "vertical",
      options: [
        { value: 0, label: "Faux" },
        { value: 1, label: "Vrai" },
      ],
      width: "200px",
      name: "response",
      label: "Reponse à la question",
    },
    {
      type: "textarea",
      direction: "vertical",
      label: "Commentaire bonne reponse",
      name: "description_true",
      placeholder: "Tapez ici...",
      width: "100%",
    },
    {
      type: "textarea",
      direction: "vertical",
      label: "Commentaire fausse reponse",
      name: "description_false",
      placeholder: "Tapez ici...",
      width: "100%",
    },
    {
      type: "file",
      direction: "vertical",
      label: "Audio Voif Off",
      name: "audio",
      placeholder: "Séléctionné un audio",
      width: "100%",
      acceptList: ".mp3",
      acceptMultipleFile: false,
    },
  ],
};
const ADD_QUESTION_REFLEXION_EDUCATION_OTHER_LANGUAGE_FORM = {
  form: {
    vertical: true,
    space: {
      direction: "vertical",
      size: "middle",
    },
  },
  fields: [
    {
      type: "select",
      direction: "vertical",
      options: [],
      width: "200px",
      name: "lang",
      label: "Langue",
    },
    {
      type: "textarea",
      direction: "vertical",
      label: "Question",
      name: "question",
      placeholder: "Tapez ici...",
      width: "100%",
    },
  ],
};
const ADD_QUESTION_EVALUATION_EDUCATION_OTHER_LANGUAGE_FORM = {
  form: {
    vertical: true,
    space: {
      direction: "vertical",
      size: "middle",
    },
  },
  fields: [
    {
      type: "select",
      direction: "vertical",
      options: [],
      width: "200px",
      name: "lang",
      label: "Langue",
    },
    {
      type: "textarea",
      direction: "vertical",
      label: "Question",
      name: "question",
      placeholder: "Tapez ici...",
      width: "100%",
    },

    {
      type: "textarea",
      direction: "vertical",
      label: "Commentaire bonne reponse",
      name: "description_true",
      placeholder: "Tapez ici...",
      width: "100%",
    },
    {
      type: "textarea",
      direction: "vertical",
      label: "Commentaire fausse reponse",
      name: "description_false",
      placeholder: "Tapez ici...",
      width: "100%",
    },
  ],
};
const ADD_FOURNISSEUR_ONE_FORM = {
  form: {
    vertical: true,
    space: {
      direction: "vertical",
      size: "middle",
    },
  },
  fields: [
    {
      type: "select",
      direction: "vertical",
      options: [],
      width: "200px",
      name: "catId",
      label: "Catégorie",
    },
    {
      type: "input",
      direction: "vertical",
      label: "Nom du fournisseur",
      name: "name_fournisseur",
      placeholder: "Nom du fournisseur",
      width: "100%",
      secured: false,
    },

    {
      type: "input",
      direction: "vertical",
      label: "Telephone",
      name: "phone",
      placeholder: "Téléphone",
      width: "100%",
      secured: false,
    },
    {
      type: "select",
      direction: "vertical",
      options: [],
      width: "200px",
      name: "provinceId",
      label: "Province",
    },
    {
      type: "select",
      direction: "vertical",
      options: [],
      width: "200px",
      name: "communeId",
      label: "Commune",
    },
  ],
};
const ADD_FOURNISSEUR_SECOND_FORM = {
  form: {
    vertical: true,
    space: {
      direction: "vertical",
      size: "middle",
    },
  },
  fields: [
    {
      type: "select",
      direction: "vertical",
      options: [],
      width: "200px",
      name: "zoneId",
      label: "Zone",
    },
    {
      type: "select",
      direction: "vertical",
      options: [],
      width: "200px",
      name: "collineId",
      label: "Colline",
    },
    {
      type: "select",
      direction: "vertical",
      options: [],
      width: "200px",
      name: "localiteId",
      label: "Localité",
    },
    {
      type: "file",
      direction: "vertical",
      label: "Logo du fournisseur",
      name: "logo",
      placeholder: "Séléctionné l'image ",
      width: "100%",
      acceptList: ".png,.jpeg,.jpg",
      acceptMultipleFile: false,
    },
  ],
};
const ADD_CATEGORY_FOURNISSEUR_FORM = {
  form: {
    vertical: true,
    space: {
      direction: "vertical",
      size: "middle",
    },
  },
  fields: [
    {
      type: "input",
      direction: "vertical",
      label: "Description",
      name: "description",
      placeholder: "Description categorie",
      width: "100%",
      secured: false,
    },
  ],
};
const ADD_CATEGORY_FOURNISSEUR_OTHER_LANGUAGE_FORM = {
  form: {
    vertical: true,
    space: {
      direction: "vertical",
      size: "middle",
    },
  },
  fields: [
    {
      type: "select",
      direction: "vertical",
      options: [],
      width: "200px",
      name: "lang",
      label: "Langue",
    },
    {
      type: "input",
      direction: "vertical",
      label: "Description",
      name: "description",
      placeholder: "Description categorie",
      width: "100%",
      secured: false,
    },
  ],
};
const ADD_SERVICE_VIDEO_FOURNISSEUR_FORM = {
  form: {
    vertical: true,
    space: {
      direction: "vertical",
      size: "middle",
    },
  },
  fields: [
    {
      type: "input",
      direction: "vertical",
      label: "Titre du service",
      name: "title",
      placeholder: "Titre",
      width: "100%",
      secured: false,
    },
    {
      type: "textarea",
      direction: "vertical",
      label: "Déscription du service",
      name: "description",
      placeholder: "Description",
      width: "100%",
    },
    {
      type: "file",
      direction: "vertical",
      label: "Image poster du service",
      name: "image",
      placeholder: "Séléctionné l'image ",
      width: "100%",
      acceptList: ".png,.jpeg,.jpg",
      acceptMultipleFile: false,
    },
    {
      type: "file",
      direction: "vertical",
      label: "Video du service",
      name: "video",
      placeholder: "Séléctionné la video",
      width: "100%",
      acceptList: ".mov,.mp4,.avi,.vmf,.flv,.webm,.m4v,.x-m4v",
      acceptMultipleFile: false,
    },
  ],
};
const ADD_SERVICE_VIDEO_FOURNISSEUR_OTHER_LANGUAGE_FORM = {
  form: {
    vertical: true,
    space: {
      direction: "vertical",
      size: "middle",
    },
  },
  fields: [
    {
      type: "select",
      direction: "vertical",
      options: [],
      width: "200px",
      name: "lang",
      label: "Langue",
    },
    {
      type: "input",
      direction: "vertical",
      label: "Titre du service",
      name: "title",
      placeholder: "Titre",
      width: "100%",
      secured: false,
    },
    {
      type: "textarea",
      direction: "vertical",
      label: "Déscription du service",
      name: "description",
      placeholder: "Description",
      width: "100%",
    },
  ],
};
const ADD_CATEGORY_PROJET_FORM = {
  form: {
    vertical: true,
    space: {
      direction: "vertical",
      size: "middle",
    },
  },
  fields: [
    {
      type: "input",
      direction: "vertical",
      label: "Description",
      name: "description",
      placeholder: "Description categorie",
      width: "100%",
      secured: false,
    },
    {
      type: "radio",
      direction: "vertical",
      name: "askSuperficie",
      label: "Demander superficie ?",
      width: "100%",
      options: [
        {
          value: 1,
          name: "OUI",
        },
        {
          value: 0,
          name: "NON",
        },
      ],
    },
  ],
};
const ADD_CATEGORY_PROJET_OTHER_LANGUAGE_FORM = {
  form: {
    vertical: true,
    space: {
      direction: "vertical",
      size: "middle",
    },
  },
  fields: [
    {
      type: "select",
      direction: "vertical",
      options: [],
      width: "200px",
      name: "lang",
      label: "Langue",
    },
    {
      type: "input",
      direction: "vertical",
      label: "Description",
      name: "description",
      placeholder: "Description categorie",
      width: "100%",
      secured: false,
    },
  ],
};

const ADD_PLANIFICATION_CULTURE_FORM = {
  form: {
    vertical: true,
    space: {
      direction: "vertical",
      size: "middle",
    },
  },
  fields: [
    {
      type: "select",
      direction: "vertical",
      options: [],
      width: "200px",
      name: "categId",
      label: "Catégorie",
    },
    {
      type: "input",
      direction: "vertical",
      label: "Nom de la culutre",
      name: "description",
      placeholder: "Nom de la culutre",
      width: "100%",
      secured: false,
    },
  ],
};
const ADD_PLANIFICATION_CULTURE_OTHER_LANGUAGE_FORM = {
  form: {
    vertical: true,
    space: {
      direction: "vertical",
      size: "middle",
    },
  },
  fields: [
    {
      type: "select",
      direction: "vertical",
      options: [],
      width: "200px",
      name: "lang",
      label: "Langue",
    },
    {
      type: "input",
      direction: "vertical",
      label: "Nom de la culutre",
      name: "description",
      placeholder: "Nom de la culutre",
      width: "100%",
      secured: false,
    },
  ],
};

const ADD_CHAMPS_FIXE_FORM = {
  form: {
    vertical: true,
    space: {
      direction: "vertical",
      size: "middle",
    },
  },
  fields: [
    {
      type: "select",
      direction: "vertical",
      options: [
        { value: 1, label: "Immobilisation" },
        { value: 2, label: "Coût fixe" },
        { value: 3, label: "Cofinancement" },
        { value: 4, label: "Fonds Propres" },
        { value: 5, label: "Projections des revenus" },
      ],
      width: "200px",
      name: "type",
      label: "Type",
    },
    {
      type: "input",
      direction: "vertical",
      label: "Nom du champs",
      name: "description",
      placeholder: "Nom du champs",
      width: "100%",
      secured: false,
    },
  ],
};
const ADD_CHAMPS_FIXE_OTHER_LANGUAGE_FORM = {
  form: {
    vertical: true,
    space: {
      direction: "vertical",
      size: "middle",
    },
  },
  fields: [
    {
      type: "select",
      direction: "vertical",
      options: [],
      width: "200px",
      name: "lang",
      label: "Langue",
    },
    {
      type: "input",
      direction: "vertical",
      label: "Nom du champs",
      name: "description",
      placeholder: "Nom du champs",
      width: "100%",
      secured: false,
    },
  ],
};
const ADD_CHAMPS_VARIABLE_FORM = {
  form: {
    vertical: true,
    space: {
      direction: "vertical",
      size: "middle",
    },
  },
  fields: [
    {
      type: "select",
      direction: "vertical",
      options: [],
      width: "200px",
      name: "categId",
      label: "Catégorie",
    },
    {
      type: "input",
      direction: "vertical",
      label: "Nom du champs",
      name: "description",
      placeholder: "Nom du champs",
      width: "100%",
      secured: false,
    },
  ],
};

const FORM_CONFIG = {
  FILTRE_RAPPORT,
  LOGIN_FORM,
  ADD_VIDEO_USAGE_FORM,
  ADD_VIDEO_USAGE_OTHER_LANGUAGE_FORM,
  ADD_EDUCATION_CATEGORY_FROM,
  ADD_EDUCATION_CATEGORY_OTHER_LANGUAGE_FORM,
  ADD_VIDEO_EDUCATION_FORM,
  ADD_VIDEO_EDUCATION_OTHER_LANGUAGE_FORM,
  ADD_QUESTION_EDUCATION_FORM,
  ADD_QUESTION_REFLEXION_EDUCATION_FORM,
  ADD_QUESTION_EVALUATION_EDUCATION_FORM,
  ADD_QUESTION_REFLEXION_EDUCATION_OTHER_LANGUAGE_FORM,
  ADD_QUESTION_EVALUATION_EDUCATION_OTHER_LANGUAGE_FORM,
  ADD_FOURNISSEUR_ONE_FORM,
  ADD_FOURNISSEUR_SECOND_FORM,
  ADD_PLANIFICATION_CULTURE_FORM,
  ADD_PLANIFICATION_CULTURE_OTHER_LANGUAGE_FORM,
  ADD_CHAMPS_FIXE_FORM,
  ADD_CHAMPS_FIXE_OTHER_LANGUAGE_FORM,
  ADD_CATEGORY_FOURNISSEUR_FORM,
  ADD_CATEGORY_FOURNISSEUR_OTHER_LANGUAGE_FORM,
  ADD_SERVICE_VIDEO_FOURNISSEUR_FORM,
  ADD_SERVICE_VIDEO_FOURNISSEUR_OTHER_LANGUAGE_FORM,
  ADD_CATEGORY_PROJET_FORM,
  ADD_CATEGORY_PROJET_OTHER_LANGUAGE_FORM,
  ADD_CHAMPS_VARIABLE_FORM,
};
export default FORM_CONFIG;
