const formatterDate = (dates) => {
  const date = new Date(dates);
  const month = date.getMonth() + 1;
  const newmonth = month < 10 ? "0" + month : month;

  const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
  const year = date.getFullYear();

  return day + "-" + newmonth + "-" + year;
};
const formatterDateBeginWithYear = (dates) => {
  const date = new Date(dates);
  const month = date.getMonth() + 1;
  const newmonth = month < 10 ? "0" + month : month;

  const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
  const year = date.getFullYear();

  return year + "-" + newmonth + "-" + day;
};

const formatterHour = (dates) => {
  const date = new Date(dates);
  return date.toLocaleTimeString();
};

const addMonths = (date, months) => {
  const newDate = new Date(date.setMonth(date.getMonth() + months));
  const day = newDate.getDate();
  const month = newDate.getMonth();
  const newmonth = month < 10 ? "0" + month : month;
  const newDay = day < 10 ? "0" + day : day;
  const year = newDate.getFullYear();
  return newDay + "-" + newmonth + "-" + year;
};

export { formatterDate, formatterHour, addMonths, formatterDateBeginWithYear };
